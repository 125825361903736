var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "old-form sr" }, [
    _c("h2", {
      staticClass: "old-form__heading",
      domProps: {
        innerHTML: _vm._s(
          _vm.newUserCreated
            ? "Thank you!<br> You have been succesfully registered"
            : "Welcome!"
        ),
      },
    }),
    _vm._v(" "),
    !_vm.newUserCreated
      ? _c(
          "form",
          {
            attrs: { action: "#", method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createUserAndSupplier($event)
              },
            },
          },
          [
            _c("div", { staticClass: "old-form__control" }, [
              _c(
                "label",
                {
                  staticClass: "old-form__label",
                  class: {
                    "old-form__label--filled": _vm.email && _vm.email.length,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "old-form__input",
                    attrs: { type: "email", placeholder: "Email" },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "old-form__placeholder" }, [
                    _vm._v("Email"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.emailError && _vm.formIsSubmitted
                ? _c("span", { staticClass: "old-form__error" }, [
                    _vm._v(" Email is not valid "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.newUserError && _vm.formIsSubmitted
                ? _c("span", { staticClass: "old-form__error" }, [
                    _vm._v(" This email is already taken "),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
          ]
        )
      : _c(
          "div",
          { staticClass: "sr__success post-content" },
          [
            _c("p", [
              _vm._v(
                "Your account has been created and a password has been sent to " +
                  _vm._s(_vm.email) +
                  "."
              ),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.supplierAdded
                ? _c(
                    "p",
                    [
                      _vm._v(
                        "\n        You will be redirected to next step in " +
                          _vm._s(_vm.timer.seconds) +
                          " seconds."
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "supplierEdit",
                              params: { id: _vm.supplierId },
                              query: { guide: true },
                            },
                          },
                        },
                        [_vm._v("Go to next step")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
    _vm._v(" "),
    _c("div", { staticClass: "loader", class: { "is-visible": _vm.loading } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "old-form__control" }, [
      _c("p", [
        _vm._v(
          "\n        Being visible and approachable is a cornerstone in creating business. We at FindSourcing want to help with\n        this in best possible way!\n      "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Firstly, please verify and enter the email you want to use above."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Then, press below button to continue the registration process."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "old-form__control" }, [
      _c(
        "button",
        { staticClass: "old-form__button", attrs: { type: "submit" } },
        [_vm._v("Confirm Your Email Address")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }