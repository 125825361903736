<template>
  <article class="old-form sr">
    <h2
      class="old-form__heading"
      v-html="newUserCreated ? 'Thank you!<br> You have been succesfully registered' : 'Welcome!'"
    ></h2>
    <form action="#" method="post" @submit.prevent="createUserAndSupplier" v-if="!newUserCreated">
      <div class="old-form__control">
        <label class="old-form__label" :class="{ 'old-form__label--filled': email && email.length }">
          <input type="email" placeholder="Email" class="old-form__input" v-model="email" />
          <span class="old-form__placeholder">Email</span>
        </label>
        <!-- Email validation errors -->
        <span class="old-form__error" v-if="emailError && formIsSubmitted"> Email is not valid </span>
        <!-- User creation errors -->
        <span class="old-form__error" v-if="newUserError && formIsSubmitted"> This email is already taken </span>
      </div>
      <div class="old-form__control">
        <p>
          Being visible and approachable is a cornerstone in creating business. We at FindSourcing want to help with
          this in best possible way!
        </p>
        <p>Firstly, please verify and enter the email you want to use above.</p>
        <p>Then, press below button to continue the registration process.</p>
      </div>
      <div class="old-form__control">
        <button type="submit" class="old-form__button">Confirm Your Email Address</button>
      </div>
    </form>
    <div class="sr__success post-content" v-else>
      <p>Your account has been created and a password has been sent to {{ email }}.</p>
      <transition name="fade">
        <p v-if="supplierAdded">
          You will be redirected to next step in {{ timer.seconds }} seconds.<br />
          <router-link :to="{ name: 'supplierEdit', params: { id: supplierId }, query: { guide: true } }"
            >Go to next step</router-link
          >
        </p>
      </transition>
    </div>
    <div class="loader" :class="{ 'is-visible': loading }"></div>
  </article>
</template>

<script>
import countDown from './../lib/timers';

export default {
  load({ store, route }) {
    return store.user.checkStatus();
  },
  data() {
    return {
      email: this.$route.query.email,
      formIsSubmitted: false,
      newUserError: false,
      newUserCreated: false,
      supplierId: null,
      suppliersName: this.$route.query.name,
      suplierAdded: false,
      loading: false,
      supplierAdded: false,
      timer: {},
    };
  },
  computed: {
    emailError() {
      const re = /\S+@\S+\.\S+/;
      return !re.test(this.email);
    },
    slug() {
      if (!this.suppliersName) return;
      return this.suppliersName
        .trim()
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/\\/g, '')
        .replace(/[^-a-z0-9]/gi, '')
        .replace(/--/g, '-');
    },
    category() {
      let c = +this.$route.query.category;
      return Number.isInteger(c) ? c : null;
    },
    supplier() {
      return {
        title: this.suppliersName,
        slug: this.slug,
        category: this.category,
        draft: true,
      };
    },
  },
  methods: {
    async createUserAndSupplier() {
      this.formIsSubmitted = true;

      if (!this.emailError) {
        this.loading = true;
        let { success, id } = await this.$store.user.registerSupplierWithoutPassword({
          email: this.email,
          supplier: this.supplier,
          satra: Boolean(this.$route.query.satra),
        });

        this.loading = false;

        if (!success) {
          this.newUserError = true;
          return;
        }

        this.newUserCreated = true;

        if (!id) {
          // TODO: FIXME - add proper error handling when registering user but not company
          return;
        }

        this.supplierAdded = true;
        this.supplierId = id;

        this.timer = countDown(this, 4, () => {
          this.$router.push({ name: 'supplierEdit', params: { id }, query: { guide: true } });
        });
      }
    },
  },
  watch: {
    // Hide errors when email is changed
    email: function () {
      if (!this.emailError) {
        this.newUserError = false;
      }
    },
  },
  mounted() {
    if (this.$store.user.isSupplier) {
      this.$router.push({ name: 'supplierEdit', params: { id: this.$store.user.company.id }, query: { guide: true } });
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

.sr {
  position: relative;
  .loader {
    position: absolute;
    height: 110%;
  }
  &__success {
    text-align: center;
  }
}
</style>
